import { FormattedMessage } from "react-intl"
import { Button } from "antd"

import { TillContainer } from "./styled"
import { TILL_STATUS_FREE, TILL_STATUS_OPEN } from "src/common/constants"

const Till = props => {
  const { till = {}, setStatus } = props
  const isTillRed = till.status !== TILL_STATUS_FREE && till.status !== TILL_STATUS_OPEN

  return <TillContainer>
    <h3><FormattedMessage id={'home.title.till'} values={{ till: till.till }} /></h3>
    <Button size={'large'} type={'primary'} danger={isTillRed} onClick={() => setStatus(till.tillId, till.status)}>
      <FormattedMessage id={`home.button.${till.status || 'DEFAULT'}`} />
    </Button>
  </TillContainer>
}

export default Till