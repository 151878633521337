const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)

const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://gtdp-uat1.priv.nprd.api.devportal.adeo.cloud/api-singleline/v1',
    API_KEY: 'mtOny6zFFffUcY83ktx1JYoNz1gRpFO8'
  },
  test: {
    LOGIN_URL: `https://test-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://gtdp-uat1.priv.nprd.api.devportal.adeo.cloud/api-singleline/v1',
    API_KEY: 'mtOny6zFFffUcY83ktx1JYoNz1gRpFO8'
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-filaunicacasse-itlm-ping-zzgcukhznq-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://gtdp.priv.api.devportal.adeo.cloud/api-singleline/v1',
    API_KEY: 'f6f181e1-0b03-475b-a9f9-8099353a6ca5'
  },
}

export default envConfig[env]