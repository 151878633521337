import { GET_STORES } from '../action-types'
import api from '../../common/api'
import { handleServerError } from '../../common/utils'

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getTillsStatus = () => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  try {
    const res = await api.tills.getStatus(storeId)
    return res.data || []
  } catch (error) {
    handleServerError(error)
  }
}

export const setTillStatus = (tillId, status) => async dispatch => {
  try {
    await api.tills.setStatus(tillId, status)
  } catch (error) {
    handleServerError(error)
  }
}

export const callTill = tillId => async dispatch => {
  try {
    await api.tills.call(tillId)
  } catch (error) {
    handleServerError(error)
  }
}