import styled from 'styled-components'

export const AppTitle = styled.h1`
  text-align: center;
`

export const TillsGrid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  max-width: 600px;
`