export const TILL_STATUS_OPEN = 'OPEN'
export const TILL_STATUS_FREE = 'FREE'
export const TILL_STATUS_BUSY = 'BUSY'

export const tillsConfigMap = {
  10: {
    layout: [24, 25, 26, 27, 28, 29, 30, 31],
    columns: 2
  },
  59: {
    layout: [11, null, 10, 5, 9, 4, 8, 3, 7, 2, 6, 1],
    columns: 2
  },
  60: {
    layout: [4, 3, null, 2, null, 1],
    columns: 2
  }
}