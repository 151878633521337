import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { Username } from './styled'

import { getShortName } from '../../common/utils'

const User = props => {
  const { user, onClick } = props
  return (
    <div onClick={onClick}>
      <Avatar size={'small'} icon={<UserOutlined />} />
      <Username>
        {getShortName(user.name, user.surname)}
        {' • '}
        <FormattedMessage
          id={'header.storeId'}
          values={{ storeId: user.storeId }}
        />
      </Username>
    </div>
  )
}
User.propTypes = {
  onClick: PropTypes.func,
  user: PropTypes.object,
}
export default User
