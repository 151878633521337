import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Result } from 'antd'

import { connect } from 'react-redux'
import { getTillsStatus, setTillStatus, callTill } from 'src/redux-store/actions/app-actions'

import Loader from 'src/components/Loader'
import Till from 'src/components/Till'
import { AppTitle, TillsGrid } from './styled'

import { tillsConfigMap, TILL_STATUS_BUSY, TILL_STATUS_FREE, TILL_STATUS_OPEN } from 'src/common/constants'

const Home = props => {
  const { intl, user, getTillsStatusAction, setTillStatusAction, callTill } = props
  const [isLoading, setIsLoading] = useState(false)
  const [tills, setTills] = useState([])

  const tillConfig = tillsConfigMap[user.storeId]

  const getTillsStatus = useCallback(async () => {
    setIsLoading(true)
    const tills = await getTillsStatusAction()
    setTills(tills)

    setIsLoading(false)
  }, [getTillsStatusAction])

  useEffect(() => {
    if (user.storeId !== 99) getTillsStatus()
  }, [user.storeId, getTillsStatus])

  const setTillStatus = useCallback(async (tillId, status) => {
    const newStatus = status === TILL_STATUS_FREE || status === TILL_STATUS_OPEN ? TILL_STATUS_BUSY : TILL_STATUS_OPEN
    setIsLoading(true)

    await setTillStatusAction(tillId, newStatus)
    if (newStatus === TILL_STATUS_OPEN) {
      await callTill(tillId)
    }

    getTillsStatus()
    setIsLoading(false)
  }, [setTillStatusAction, getTillsStatus, callTill])

  return isLoading ? <Loader /> : <>
    <AppTitle><FormattedMessage id={'home.title'} /></AppTitle>
    <TillsGrid columns={tillConfig?.columns}>
      {tillConfig
        ? tillConfig.layout.map(till =>
          till ? <Till key={till} till={tills.find(t => t.till === till)} setStatus={setTillStatus} /> : <br />
        )
        : <Result status={404} title={intl.formatMessage({ id: 'app.store.isNotEnabled' })} />}
    </TillsGrid>
  </>

}

const mapStateToProps = state => ({
  user: state.user.current
})
const mapDispatchToProps = {
  getTillsStatusAction: getTillsStatus,
  setTillStatusAction: setTillStatus,
  callTill
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))