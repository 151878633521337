import { UPDATE_STORE_ID, VALIDATE_TOKEN } from '../action-types'

const initialState = { current: {} }
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case VALIDATE_TOKEN:
      return { ...state, current: payload }
    case UPDATE_STORE_ID:
      return { ...state, current: { ...state.current, storeId: payload } }
    default:
      return state
  }
}
export default userReducer